import React from 'react';
import ReactEcharts from "echarts-for-react";
import { color } from 'echarts';

export default function PieChart(props) {

  const { listX, getDetails, sortX } = props;



  let colorList = [
    "#64B5F6",
    "#4DD0E1",
    "#B388FF",
    "#4DB6AC",
    "#FFB74D",
    "#FF8A65",
  ]


  let dataNew = []
  if (listX && listX.length > 0) {
    listX.forEach((ele) => {
      dataNew.push({
        title: getDetails(ele, "label"),
        imgSrc: getDetails(ele, "imgSrc"),
        description: getDetails(ele, "desc"),
        score: getDetails(ele, "score").toString()
      })
      dataNew.sort((b, a) => Number(a.score) - Number(b.score));
    })
  }
  console.log('listX--///--getAptitudeDetails----------->', listX)
  console.log('dataNew--///--getAptitudeDetails----------->', dataNew)


  const numericalTest = [
    {
      title: "Logical Reasoning",
      value: "25",
      color: "#64B5F6",
    },
    {
      title: "Numerical Ability",
      value: "35",
      color: "#64B5F6",
    },
    {
      title: "Verbal Aptitude",
      value: "68",
      color: "#64B5F6",
    },
    {
      title: "Spatial Reasoning",
      value: "38",
      color: "#64B5F6",
    },
    {
      title: "Mechanical Reasoning ",
      value: "10",
      color: "#64B5F6",
    },
    {
      title: "Interpersonal and Communication Skills",
      value: "75",
      color: "#64B5F6",
    }
  ];

  return (
    <>

      <div className="pt-2 flex justify-center">

        <div className="relative w-full px-3 py-4 ">
          <div className='space-y-6 '>
            {dataNew.map((itmes, index) => (
              <div key={index} className='flex items-center flex-shrink-0 space-x-2 text-[#212121]'>
                <span className='text-right w-60'>{itmes.title}</span>
                <span>-</span>
                <div className='w-full bg-[#f5f5f5] h-8 flex flex-row justify-start'>
                  <div className='flex items-center justify-start h-8' style={{ width: `${itmes.score}%`, backgroundColor: colorList[index] }}>
                  </div>
                  <span className='flex items-center text-center p-2 text-[#212121]'>{itmes.score}%</span>
                </div>
              </div>
            ))}
          </div>
        </div>

      </div>

      <h2 className="text-xl font-bold text-center uppercase">Your Top 3 Potential Areas</h2>
      <div className="w-full mx-auto lg:w-9/12">
        <div className="grid grid-cols-3 gap-2">
          {dataNew && dataNew.length > 0 ?
            dataNew.map((ele, index) =>
              (index < 3) ?
                <div className="bg-[#757575] p-2 text-center font-medium" style={{ background: colorList[index] }}>
                  <p>{ele["title"]+" ("+ele.score+"%)"}</p>
                </div>
                : null
            ) : null}
          {/* <div className="bg-[#757575] p-2 text-center font-medium">
            <p>Mechanical (78)</p>
          </div>
          <div className="bg-[#757575] p-2 text-center font-medium">
            <p>Verbal (78)</p>
          </div>
          <div className="bg-[#757575] p-2 text-center font-medium">
            <p>Numerical (78)</p>
          </div> */}
        </div>
      </div>

    </>
  );
}

