import {
  assessmentConstants
} from '../_constants';

export function assessment(state = {}, action) {
  // console.log('state',state,action)
  switch (action.type) {


    case assessmentConstants.USER_ASSESSMENT_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userAssessmentUserProfileData: action.assessment.userAssessmentUserProfileData,

      };
    case assessmentConstants.USER_ASSESSMENT_USER_PROFILE_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case assessmentConstants.SUBMIT_RATING_EXPERT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.SUBMIT_RATING_EXPERT_SUCCESS:
      return {
        ...state,
        submitExpertRatingData: action.assessment.submitExpertRatingData

      };
    case assessmentConstants.SUBMIT_RATING_EXPERT_FAILURE:
      return {
        ...state,
        error: action.error
      };




      
    case assessmentConstants.GENERATE_CAREER_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        generateCareerReportLoading:true
      };
    case assessmentConstants.GENERATE_CAREER_REPORT_SUCCESS:
      return {
        ...state,
        generateCareerReportLoading:false,
        generateCareerReportData: action.assessment.generateCareerReportData

      };
    case assessmentConstants.GENERATE_CAREER_REPORT_FAILURE:
      return {
        ...state,
        generateCareerReportLoading:false,
        error: action.error
      };


      
      case assessmentConstants.GET_TALENT_EMP_SUMMARYU_REQUEST:
        return {
          ...state,
          loading: true
        };
      case assessmentConstants.GET_TALENT_EMP_SUMMARYU_SUCCESS:
        return {
          ...state,
          loading: false,
          getTalentPlusEmpSummarySuccess: true,
          getCareerFilamentScoreData: action.assessment.getCareerFilamentScoreData
  
        };
      case assessmentConstants.GET_TALENT_EMP_SUMMARYU_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };
  
  

        

    case assessmentConstants.GET_ASSESSMENT_PACKAGE_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ASSESSMENT_PACKAGE_LIST_SUCCESS:
      return {
        ...state,
        getAssessmentPackagesListData: action.assessment.getAssessmentPackagesListData,

      };
    case assessmentConstants.GET_ASSESSMENT_PACKAGE_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case assessmentConstants.GET_EXPERT_DETAILS_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_EXPERT_DETAILS_LIST_SUCCESS:
      return {
        ...state,
        getExpertDetailsListData: action.assessment.getExpertDetailsListData,

      };
    case assessmentConstants.GET_EXPERT_DETAILS_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case assessmentConstants.USER_ASSESSMENT_PACKAGE_BUY_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_PACKAGE_BUY_SUCCESS:
      return {
        ...state,
        userAssessmentPackageBuyData: action.assessment.userAssessmentPackageBuyData,

      };
    case assessmentConstants.USER_ASSESSMENT_PACKAGE_BUY_FAILURE:
      return {
        ...state,
        error: action.error
      };



    case assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDERS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDERS_SUCCESS:
      return {
        ...state,
        userAssessmentOrdersData: action.assessment.userAssessmentOrdersData,

      };
    case assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDERS_FAILURE:
      return {
        ...state,
        error: action.error
      };




    case assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_SUCCESS:
      return {
        ...state,
        userAssessmentSurveyLoginData: action.assessment.userAssessmentSurveyLoginData,

      };
    case assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_FAILURE:
      return {
        ...state,
        error: action.error
      };



    case assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_SUCCESS:
      return {
        ...state,
        userAssessmentSurveyLoginByOTPData: action.assessment.userAssessmentSurveyLoginByOTPData,

      };
    case assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_FAILURE:
      return {
        ...state,
        error: action.error
      };



    case assessmentConstants.GET_ORDER_BOOK_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ORDER_BOOK_LIST_SUCCESS:
      return {
        ...state,
        getOrderBookListData: action.assessment.getOrderBookListData,

      };
    case assessmentConstants.GET_ORDER_BOOK_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case assessmentConstants.USER_ASSESSMENT_RESPONSE_ANALYTICS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_RESPONSE_ANALYTICS_SUCCESS:
      return {
        ...state,
        userAssessmentResponseAnalyticsData: action.assessment.userAssessmentResponseAnalyticsData,

      };
    case assessmentConstants.USER_ASSESSMENT_RESPONSE_ANALYTICS_FAILURE:
      return {
        ...state,
        error: action.error
      };



    case assessmentConstants.USER_ASSESSMENT_ORDER_NAME_UPDATE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_ORDER_NAME_UPDATE_SUCCESS:
      return {
        ...state,
        userAssessmentOrderNameUpdateData: action.assessment.userAssessmentOrderNameUpdateData,

      };
    case assessmentConstants.USER_ASSESSMENT_ORDER_NAME_UPDATE_FAILURE:
      return {
        ...state,
        error: action.error
      };



    case assessmentConstants.GET_ASSESSMENT_PACKAGE_BY_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ASSESSMENT_PACKAGE_BY_ID_SUCCESS:
      return {
        ...state,
        getAssessmentPackagesByIdData: action.assessment.getAssessmentPackagesByIdData,

      };
    case assessmentConstants.GET_ASSESSMENT_PACKAGE_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case assessmentConstants.GET_ASSESSMENT_CART_ITEMS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ASSESSMENT_CART_ITEMS_SUCCESS:
      return {
        ...state,
        getAssessmentCartItemsData: action.assessment.getAssessmentCartItemsData,

      };
    case assessmentConstants.GET_ASSESSMENT_CART_ITEMS_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case assessmentConstants.GET_ASSESSMENT_CART_COUNT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ASSESSMENT_CART_COUNT_SUCCESS:
      return {
        ...state,
        getAssessmentCartCountData: action.assessment.getAssessmentCartCountData,

      };
    case assessmentConstants.GET_ASSESSMENT_CART_COUNT_FAILURE:
      return {
        ...state,
        error: action.error
      };



    case assessmentConstants.GET_ASSESSMENT_CART_PRICE_TOTAL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ASSESSMENT_CART_PRICE_TOTAL_SUCCESS:
      return {
        ...state,
        getAssessmentCartPriceTotalData: action.assessment.getAssessmentCartPriceTotalData,

      };
    case assessmentConstants.GET_ASSESSMENT_CART_PRICE_TOTAL_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case assessmentConstants.SAVE_ASSESSMENT_CONTACT_US_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.SAVE_ASSESSMENT_CONTACT_US_SUCCESS:
      return {
        ...state,
        saveAssessmentContactUsData: action.assessment.saveAssessmentContactUsData,

      };
    case assessmentConstants.SAVE_ASSESSMENT_CONTACT_US_FAILURE:
      return {
        ...state,
        error: action.error
      };




    case assessmentConstants.UPDATE_ASSESSMENT_USER_PROFILE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.UPDATE_ASSESSMENT_USER_PROFILE_SUCCESS:
      return {
        ...state,
        updateAssessmentUserProfileData: action.assessment.updateAssessmentUserProfileData,

      };
    case assessmentConstants.UPDATE_ASSESSMENT_USER_PROFILE_FAILURE:
      return {
        ...state,
        error: action.error
      };




    case assessmentConstants.ASSESSMENT_DOWNLOAD_STATUS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.ASSESSMENT_DOWNLOAD_STATUS_SUCCESS:
      return {
        ...state,
        assessmentDownloadStatusData: action.assessment.assessmentDownloadStatusData,

      };
    case assessmentConstants.ASSESSMENT_DOWNLOAD_STATUS_FAILURE:
      return {
        ...state,
        error: action.error
      };




    case assessmentConstants.ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        getAssessmentPackagesShortListData: action.assessment.getAssessmentPackagesShortListData,

      };
    case assessmentConstants.ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_FAILURE:
      return {
        ...state,
        error: action.error
      };



    case assessmentConstants.USER_ASSESSMENT_LOGOUT_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_LOGOUT_SUCCESS:
      return {
        ...state,
        AssessmentUserLogoutData: action.assessment.AssessmentUserLogoutData,

      };
    case assessmentConstants.USER_ASSESSMENT_LOGOUT_FAILURE:
      return {
        ...state,
        error: action.error
      };



    case assessmentConstants.USER_PLACE_PAYMENT_ORDER_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_PLACE_PAYMENT_ORDER_SUCCESS:
      return {
        ...state,
        userPlacePaymentOrderData: action.assessment.userPlacePaymentOrderData,

      };
    case assessmentConstants.USER_PLACE_PAYMENT_ORDER_FAILURE:
      return {
        ...state,
        error: action.error
      };




    case assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDER_PLACE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDER_PLACE_SUCCESS:
      return {
        ...state,
        userAssessmentPackageOrderPlaceData: action.assessment.userAssessmentPackageOrderPlaceData,

      };
    case assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDER_PLACE_FAILURE:
      return {
        ...state,
        error: action.error
      };



    case assessmentConstants.ASSESSMENT_CART_BY_PRE_ORDER_ID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.ASSESSMENT_CART_BY_PRE_ORDER_ID_SUCCESS:
      return {
        ...state,
        getAssessmentCartByPreOrderIdList: action.assessment.getAssessmentCartByPreOrderIdData.cartList,
        getAssessmentCartByPreOrderNumberData: action.assessment.getAssessmentCartByPreOrderIdData.orderNumber,
        getAssessmentCartByPreOrderCodeData: action.assessment.getAssessmentCartByPreOrderIdData.preOrderCode,
        getAssessmentCartByOrderNumber: action.assessment.getAssessmentCartByPreOrderIdData.orderNumber
      };
    case assessmentConstants.ASSESSMENT_CART_BY_PRE_ORDER_ID_FAILURE:
      return {
        ...state,
        error: action.error
      };



    case assessmentConstants.GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_SUCCESS:
      return {
        ...state,
        getAssessmentCartByPreOrderPriceTotalData: action.assessment.getAssessmentCartByPreOrderPriceTotalData,

      };
    case assessmentConstants.GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_FAILURE:
      return {
        ...state,
        error: action.error
      };



    case assessmentConstants.CREATE_SUCCESS_ORDER_BOOK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.CREATE_SUCCESS_ORDER_BOOK_SUCCESS:
      return {
        ...state,
        createSuccessOrderBookData: action.assessment.createSuccessOrderBookData

      };
    case assessmentConstants.CREATE_SUCCESS_ORDER_BOOK_FAILURE:
      return {
        ...state,
        error: action.error
      };


      case assessmentConstants.SAVE_SUCCESS_ORDER_BOOK_REQUEST:
        return {
          ...state,
          loading: true
        };
      case assessmentConstants.SAVE_SUCCESS_ORDER_BOOK_SUCCESS:
        return {
          ...state,
          saveSuccessOrderBookData: action.assessment.saveSuccessOrderBookData
  
        };
      case assessmentConstants.SAVE_SUCCESS_ORDER_BOOK_FAILURE:
        return {
          ...state,
          error: action.error
        };






    case assessmentConstants.USER_EXPERT_ORDER_PLACE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.USER_EXPERT_ORDER_PLACE_SUCCESS:
      return {
        ...state,
        userAssessmentExpertOrderPlaceData: action.assessment.userAssessmentExpertOrderPlaceData

      };
    case assessmentConstants.USER_EXPERT_ORDER_PLACE_FAILURE:
      return {
        ...state,
        error: action.error
      };



    case assessmentConstants.EXPERT_BOOKING_SUCCESS_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.EXPERT_BOOKING_SUCCESS_SUCCESS:
      return {
        ...state,
        expertBookingSuccessData: action.assessment.expertBookingSuccessData

      };
    case assessmentConstants.EXPERT_BOOKING_SUCCESS_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case assessmentConstants.EXPERT_ORDER_BOOKID_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.EXPERT_ORDER_BOOKID_SUCCESS:
      return {
        ...state,
        getExpertOrderBookIdData: action.assessment.getExpertOrderBookIdData

      };
    case assessmentConstants.EXPERT_ORDER_BOOKID_FAILURE:
      return {
        ...state,
        error: action.error
      };




    case assessmentConstants.EXPERT_ORDER_BOOK_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.EXPERT_ORDER_BOOK_SUCCESS:
      return {
        ...state,
        getExpertOrderBookListData: action.assessment.getExpertOrderBookListData

      };
    case assessmentConstants.EXPERT_ORDER_BOOK_FAILURE:
      return {
        ...state,
        error: action.error
      };

    case assessmentConstants.GET_TALENT_RESOURCES_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_TALENT_RESOURCES_SUCCESS:
      return {
        ...state,
        getTalentResourcesData: action.assessment.getTalentResourcesData

      };
    case assessmentConstants.GET_TALENT_RESOURCES_FAILURE:
      return {
        ...state,
        error: action.error
      };


    case assessmentConstants.GET_TEST_SURVEY_QUESTION_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_TEST_SURVEY_QUESTION_SUCCESS:
      return {
        ...state,
        loading: false,
        getTestSurveyQuestionsDataSuccess: true,
        getTestSurveyQuestionsData: action.assessment.getTestSurveyQuestionsData.list,
        getTestSurveyQuestionsDataTotal: action.assessment.getTestSurveyQuestionsData.total

      };
    case assessmentConstants.GET_TEST_SURVEY_QUESTION_FAILURE:
      return {
        ...state,
        loading: false,
        getTestModuleListFailed: true,
        error: action.error
      };


    case assessmentConstants.GET_INTEREST_DSCORE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_INTEREST_DSCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        getInterestDimensionScoreSuccess: true,
        getInterestDimensionScoreData: action.assessment.getInterestDimensionScore

      };
    case assessmentConstants.GET_INTEREST_DSCORE_FAILURE:
      return {
        ...state,
        loading: false,
        getWorkValueDimensionScoreFailed: true,
        error: action.error
      };

    case assessmentConstants.GET_WORKER_VALUE_DSCORE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_WORKER_VALUE_DSCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        getWorkValueDimensionScoreSuccess: true,
        getWorkValueDimensionScoreData: action.assessment.getWorkValueDimensionScore

      };
    case assessmentConstants.GET_WORKER_VALUE_DSCORE_FAILURE:
      return {
        ...state,
        loading: false,
        getWorkValueDimensionScoreFailed: true,
        error: action.error
      };




    case assessmentConstants.GET_FILAMENT_SCORE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_FILAMENT_SCORE_SUCCESS:
      return {
        ...state,
        loading: false,
        getCareerFilamentScoreSuccess: true,
        getCareerFilamentScoreData: action.assessment.getCareerFilamentScoreData

      };
    case assessmentConstants.GET_FILAMENT_SCORE_FAILURE:
      return {
        ...state,
        loading: false,
        getCareerFilamentScoreFailed: true,
        error: action.error
      };




      case assessmentConstants.GET_TALENT_PLUS_EMP_ADMIN_STATUS_REQUEST:
        return {
          ...state,
          loading: true
        };
      case assessmentConstants.GET_TALENT_PLUS_EMP_ADMIN_STATUS_SUCCESS:
        return {
          ...state,
          loading: false,
          getTalentPlusEmpAdminStatusSuccess: true,
          getTalentPlusEmpAdminStatusData: action.assessment.getTalentPlusEmpAdminStatusData
  
        };
      case assessmentConstants.GET_TALENT_PLUS_EMP_ADMIN_STATUS_FAILURE:
        return {
          ...state,
          loading: false,
          getCareerFilamentScoreFailed: true,
          error: action.error
        };
  




      case assessmentConstants.GET_TALENT_PLUS_EMP_STATUS_REQUEST:
        return {
          ...state,
          loading: true
        };
      case assessmentConstants.GET_TALENT_PLUS_EMP_STATUS_SUCCESS:
        return {
          ...state,
          loading: false,
          getTalentPlusEmpStatusSuccess: true,
          getTalentPlusEmpStatusData: action.assessment.getTalentPlusEmpStatusData
  
        };
      case assessmentConstants.GET_TALENT_PLUS_EMP_STATUS_FAILURE:
        return {
          ...state,
          loading: false,
          getTalentPlusEmpStatusFailed: true,
          error: action.error
        };
  
  


    case assessmentConstants.GET_APTITUDE_TEST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_APTITUDE_TEST_SUCCESS:
      return {
        ...state,
        loading: false,
        getAptitudeTestScoreSuccess: true,
        getAptitudeTestScoreData: action.assessment.getAptitudeTestScoreData

      };
    case assessmentConstants.GET_APTITUDE_TEST_FAILURE:
      return {
        ...state,
        loading: false,
        getAptitudeTestScoreFailed: true,
        error: action.error
      };

    case assessmentConstants.GET_PERSONALITY_INDEX_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_PERSONALITY_INDEX_SUCCESS:
      return {
        ...state,
        loading: false,
        getPersonalityIndexSuccess: true,
        getPersonalityIndexData: action.assessment.getPersonalityIndex

      };
    case assessmentConstants.GET_PERSONALITY_INDEX_FAILURE:
      return {
        ...state,
        loading: false,
        getPersonalityIndexFailed: true,
        error: action.error
      };

    case assessmentConstants.GET_CAREER_SURVEY_LIST_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_CAREER_SURVEY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        getCareerSurveyListSuccess: true,
        getCareerSurveyListData: action.assessment.getCareerSurveyList

      };
    case assessmentConstants.GET_CAREER_SURVEY_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        getCareerSurveyListFailed: true,
        error: action.error
      };


    case assessmentConstants.GET_TEST_MODULE_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_TEST_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        getTestModuleSuccess: true,
        getTestModuleData: action.assessment.getTestModuleData

      };
    case assessmentConstants.GET_TEST_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        getTestModuleListFailed: true,
        error: action.error
      };


    case assessmentConstants.GET_TALENT_BLOG_REQUEST:
      return {
        ...state,
        loading: true
      };
    case assessmentConstants.GET_TALENT_BLOG_SUCCESS:
      return {
        ...state,
        loading: false,
        getTalentBlogListSuccess: true,
        getTalentBlogData: action.assessment.getTalentBlogListData.list,
        getTalentBlogTotal: action.assessment.getTalentBlogListData.total

      };
    case assessmentConstants.GET_TALENT_BLOG_FAILURE:
      return {
        ...state,
        loading: false,
        getTalentBlogListFailed: true,
        error: action.error
      };






    default:
      return state
  }
}