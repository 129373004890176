import { CONST } from '../_config';
import { authHeader } from '../_helpers';
import { authHeaderUser } from '../_helpers';
import CryptoJS from 'crypto-js';

export const assessmentService = {
    userAssessmentUserProfile,
    userAssessmentPackageBuy,
    userAssessmentOrders,
    userAssessmentSurveyLogin,

    userAssessmentSurveyLoginByOTP,

    getAssessmentPackagesList,
    getAssessmentPackagesById,
    assessmentAddToCart,
    getAssessmentCartItems,
    updateCartItemQuantity,
    getAssessmentCartPriceTotal,
    deleteCartItem,
    getOrderBookList,
    userAssessmentResponseAnalytics,
    userAssessmentOrderNameUpdate,
    getAssessmentCartCount,
    saveAssessmentContactUs,
    updateAssessmentUserProfile,
    assessmentDownloadStatus,
    getAssessmentPackagesShortList,
    userAssesmentlogout,
    userPlacePaymentOrder,
    userAssessmentPackageOrderPlace,

    getAssessmentCartByPreOrderId,
    getAssessmentCartByPreOrderPriceTotal,

    createSuccessOrderBook,
    saveSuccessOrderBook,
    
    assessmentLogout,
    surveyLogout,

    getExpertDetailsList,
    userAssessmentExpertOrderPlace,
    expertBookingSuccess,
    getExpertOrderBookList,
    getExpertOrderBookId,

    submitExpertRating,
    getTalentResources,
    getTalentBlogList,
    getTestModule,
    getCareerSurveyList,


    getPersonalityIndex,
    getWorkValueDimensionScore,
    getInterestDimensionScore,
    getCareerFilamentScore,

    getTestSurveyQuestions,

    getPersonalityIndex_User,
    getWorkValueDimensionScore_User,
    getInterestDimensionScore_User,
    getCareerFilamentScore_User,
    getAptitudeTestScore_User,
    generateCareerReport,
    getTalentPlusEmpSummary,
    getTalentPlusEmpStatus,
    getAptitudeTestScore,
    getTalentPlusEmpAdminStatus
};



function assessmentLogout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/assessment/sign-in";
    window.location.reload();
}

function surveyLogout() {
    localStorage.removeItem('surveyuser');
    window.location.href = "#/assessment/surveylogin";
    window.location.reload();
}





function getInterestDimensionScore(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getInterestDimensionScore`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getInterestDimensionScore: data.data
            }
            //console.log('getInterestDimensionScoregetInterestDimensionScoregetInterestDimensionScore', questionObj);
            return questionObj;
        });
}

function getWorkValueDimensionScore(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getWorkValueDimensionScore`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getWorkValueDimensionScore: data.data
            }
            //console.log('getWorkValueDimensionScoregetWorkValueDimensionScoregetWorkValueDimensionScore', questionObj);
            return questionObj;
        });
}


function getTalentPlusEmpAdminStatus(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getTalentPlusEmpAdminStatus`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getTalentPlusEmpAdminStatusData: data.data
            }
            return questionObj;
        });
}



function getCareerFilamentScore(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getCareerFilamentScore`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getCareerFilamentScoreData: data.data
            }
            return questionObj;
        });
}

function getAptitudeTestScore_User(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getAptitudeTestScore_User`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getAptitudeTestScoreData: data.data
            }
            return questionObj;
        });
}

function getAptitudeTestScore(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getAptitudeTestScore`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getAptitudeTestScoreData: data.data
            }
            return questionObj;
        });
}

function generateCareerReport(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/generateCareerReport`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                generateCareerReportData: data.data
            }
            return questionObj;
        });
}



function getPersonalityIndex(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getPersonalityIndex`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getPersonalityIndex: data.data
            }
            return questionObj;
        });
}






function getInterestDimensionScore_User(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getInterestDimensionScore_User`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getInterestDimensionScore: data.data
            }
            return questionObj;
        });
}






function getTalentPlusEmpSummary(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getTalentPlusEmpSummary`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getCareerFilamentScoreData: data.data
            }
            return questionObj;
        });
}




function getTalentPlusEmpStatus(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getTalentPlusEmpStatus`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getTalentPlusEmpStatusData: data.data
            }
            return questionObj;
        });
}


function getWorkValueDimensionScore_User(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getWorkValueDimensionScore_User`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getWorkValueDimensionScore: data.data
            }
            //console.log('getWorkValueDimensionScoregetWorkValueDimensionScoregetWorkValueDimensionScore', questionObj);
            return questionObj;
        });
}

function getCareerFilamentScore_User(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getCareerFilamentScore_User`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getCareerFilamentScoreData: data.data
            }
            return questionObj;
        });
}

function getPersonalityIndex_User(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getPersonalityIndex_User`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getPersonalityIndex: data.data
            }
            return questionObj;
        });
}















function getCareerSurveyList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getCareerSurveyList`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                getCareerSurveyListData: data.data
            }
            //console.log('getCareerSurveyListgetCareerSurveyListgetCareerSurveyList', questionObj);
            return questionObj;
        });
}


function getTestSurveyQuestions(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getTestSurveyQuestions`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questionObj = {
                getTestSurveyQuestionsData: data.data
            }
            return questionObj;
        });
}

function getTestModule(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getTestModule`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questionObj = {
                getTestModuleData: data.data
            }
            //console.log('getTestModulegetTestModulegetTestModulegetTestModulegetTestModule', questionObj);
            return questionObj;
        });
}

function getTalentBlogList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getTalentBlogList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questionObj = {
                getTalentBlogListData: data.data
            }
            return questionObj;
        });
}


function submitExpertRating(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/submitExpertRating`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questionObj = {
                submitExpertRatingData: data.data
            }
            return questionObj;
        });
}

function expertBookingSuccess(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/expertBookingSuccess`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questionObj = {
                expertBookingSuccessData: data.data
            }
            return questionObj;
        });
}


function getExpertOrderBookId(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getExpertOrderBookById`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questionObj = {
                getExpertOrderBookIdData: data.data
            }
            return questionObj;
        });
}



function getExpertOrderBookList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getExpertOrderBookList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questionObj = {
                getExpertOrderBookListData: data.data
            }
            return questionObj;
        });
}


function getExpertDetailsList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getExpertDetailsList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questionObj = {
                getExpertDetailsListData: data.data
            }
            return questionObj;
        });
}

function userAssessmentExpertOrderPlace(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/userAssessmentExpertOrderPlace`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questionObj = {
                userAssessmentExpertOrderPlaceData: data.data
            }
            return questionObj;
        });
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // assessmentLogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                // assessmentLogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}


function handleResponseAdmin(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                assessmentLogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                assessmentLogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function handleResponseSurvey(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                surveyLogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                surveyLogout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}




function userAssessmentUserProfile(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/userAssessmentUserProfile`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questionObj = {
                userAssessmentUserProfileData: data.data
            }
            return questionObj;
        });
}





function userAssessmentPackageBuy(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/userAssessmentPackageBuy`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                userAssessmentPackageBuyData: data.data
            }
            return questionObj;
        });
}




function userAssessmentOrders(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/userAssessmentOrders`, requestOptions)
        .then(handleResponseAdmin)
        .then(data => {
            let questionObj = {
                userAssessmentOrdersData: data.data
            }
            return questionObj;
        });
}


function userAssessmentSurveyLogin(data) {

    let header = new Headers({
        'Content-Type': 'application/json'
        // 'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/userAssessmentSurveyLogin`, requestOptions)
        .then(handleResponseSurvey)
        .then(assessment => {


            //console.log("assessment----->", assessment)

            if (assessment.data) {

                if (assessment && assessment.data) {
                    let cryptedText = CryptoJS.AES.encrypt(JSON.stringify(assessment.data), CONST.CRYPTO_KEY).toString();
                    localStorage.setItem('surveyuser', JSON.stringify(cryptedText));
                }

                // localStorage.setItem('surveyuser', JSON.stringify(assessment.data));
            }


            let questionObj = {
                userAssessmentSurveyLoginData: assessment.data
            }
            return questionObj;
        });
}


function userAssessmentSurveyLoginByOTP(data) {

    let header = new Headers({
        'Content-Type': 'application/json'
        // 'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/userAssessmentSurveyLoginByOTP`, requestOptions)
        .then(handleResponseSurvey)
        .then(assessment => {
            let questionObj = {
                userAssessmentSurveyLoginByOTPData: assessment.data
            }

            if (assessment.data) {

                if (assessment && assessment.data) {
                    let cryptedText = CryptoJS.AES.encrypt(JSON.stringify(assessment.data), CONST.CRYPTO_KEY).toString();
                    localStorage.setItem('surveyuser', JSON.stringify(cryptedText));
                }


                // localStorage.setItem('adminuser', JSON.stringify(assessment.data));
            }
            return questionObj;
        });
}

function getAssessmentPackagesList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        // 'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getAssessmentPackagesList`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                getAssessmentPackagesListData: assessment.data
            }
            return questionObj;
        });
}

function getAssessmentPackagesById(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        // 'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getAssessmentPackagesById`, requestOptions)
        .then(handleResponse)
        .then(assessment => {
            let questionObj = {
                getAssessmentPackagesByIdData: assessment.data
            }
            return questionObj;
        });
}

function assessmentAddToCart(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/assessmentAddToCart`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                assessmentAddToCartData: assessment.data
            }
            return questionObj;
        });
}

function getAssessmentCartItems(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getAssessmentCartItems`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                getAssessmentCartItemsData: assessment.data
            }
            return questionObj;
        });
}

function updateCartItemQuantity(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/updateCartItemQuantity`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                updateCartItemQuantityData: assessment.data
            }
            return questionObj;
        });
}
function getAssessmentCartPriceTotal(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getAssessmentCartPriceTotal`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                getAssessmentCartPriceTotalData: assessment.data
            }
            return questionObj;
        });
}

function deleteCartItem(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/deleteCartItem`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                deleteCartItemData: assessment.data
            }
            return questionObj;
        });
}

function getOrderBookList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getOrderBookList`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                getOrderBookListData: assessment.data
            }
            return questionObj;
        });
}


function userAssessmentResponseAnalytics(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/userAssessmentResponseAnalytics`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                userAssessmentResponseAnalyticsData: assessment.data
            }
            return questionObj;
        });
}


function userAssessmentOrderNameUpdate(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/userAssessmentOrderNameUpdate`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                userAssessmentOrderNameUpdateData: assessment.data
            }
            return questionObj;
        });
}

function getAssessmentCartCount(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getAssessmentCartCount`, requestOptions)
        .then(handleResponse)
        .then(assessment => {
            let questionObj = {
                getAssessmentCartCountData: assessment.data
            }
            return questionObj;
        });
}




function saveAssessmentContactUs(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/saveAssessmentContactUs`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                saveAssessmentContactUsData: assessment.data
            }
            return questionObj;
        });
}


function updateAssessmentUserProfile(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/updateAssessmentUserProfile`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                updateAssessmentUserProfileData: assessment.data
            }
            return questionObj;
        });
}
function assessmentDownloadStatus(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/assessmentDownloadStatus`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                assessmentDownloadStatusData: assessment.data
            }
            return questionObj;
        });
}
function getAssessmentPackagesShortList(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        // 'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getAssessmentPackagesShortList`, requestOptions)
        .then(handleResponse)
        .then(assessment => {
            let questionObj = {
                getAssessmentPackagesShortListData: assessment.data
            }
            return questionObj;
        });
}
function userAssesmentlogout(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/userAssesmentlogout`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                userAssesmentlogoutData: assessment.data
            }
            return questionObj;
        });
}

function userPlacePaymentOrder(data) {
}

function userAssessmentPackageOrderPlace(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/userAssessmentPackageOrderPlace`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                userAssessmentPackageOrderPlaceData: assessment.data
            }
            return questionObj;
        });
}

function getAssessmentCartByPreOrderId(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getAssessmentCartByPreOrderId`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                getAssessmentCartByPreOrderIdData: assessment.data
            }
            return questionObj;
        });
}

function getAssessmentCartByPreOrderPriceTotal(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getAssessmentCartByPreOrderPriceTotal`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                getAssessmentCartByPreOrderPriceTotalData: assessment.data
            }
            return questionObj;
        });
}

function createSuccessOrderBook(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/createSuccessOrderBook`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                createSuccessOrderBookData: assessment.data
            }
            return questionObj;
        });
}
function saveSuccessOrderBook(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/saveSuccessOrderBook`, requestOptions)
        .then(handleResponseAdmin)
        .then(assessment => {
            let questionObj = {
                saveSuccessOrderBookData: assessment.data
            }
            return questionObj;
        });
}


function getTalentResources(data) {

    let header = new Headers({
        'Content-Type': 'application/json',
        'Authorization': authHeaderUser().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch(CONST.BACKEND_URL + `/emprole/getTalentResources`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questionObj = {
                getTalentResourcesData: data.data
            }
            return questionObj;
        });
}

