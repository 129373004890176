import { assessmentConstants } from '../_constants';
import { assessmentService } from '../_services';
import { alertActions, questionsActions } from '.';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
export const assessmentActions = {

    userAssessmentUserProfile,
    assessmentLogout,

    userAssessmentLogout,
    userAssessmentOrders,

    userAssessmentSurveyLogin,

    userAssessmentSurveyLoginByOTP,


    getAssessmentPackagesList,
    getAssessmentPackagesById,
    assessmentAddToCart,
    getAssessmentCartItems,
    updateCartItemQuantity,
    getAssessmentCartPriceTotal,
    deleteCartItem,
    // userAssessmentPackageBuy,
    userPlacePaymentOrder,
    getOrderBookList,
    userAssessmentResponseAnalytics,
    userAssessmentOrderNameUpdate,
    getAssessmentCartCount,
    saveAssessmentContactUs,
    updateAssessmentUserProfile,
    assessmentDownloadStatus,
    AssessmentUserLogout,
    getAssessmentPackagesShortList,
    userAssessmentPackageOrderPlace,

    getAssessmentCartByPreOrderId,
    getAssessmentCartByPreOrderPriceTotal,

    createSuccessOrderBook,
    saveSuccessOrderBook,

    getExpertDetailsList,
    userAssessmentExpertOrderPlace,
    expertBookingSuccess,
    getExpertOrderBookList,
    getExpertOrderBookId,
    submitExpertRating,
    userAssessmentExpertOrderEmpty,
    getTalentResources,
    getTalentBlogList,
    getTestModule,
    getCareerSurveyList,


    getTestSurveyQuestions,

    getPersonalityIndex,
    getWorkValueDimensionScore,
    getInterestDimensionScore,
    getCareerFilamentScore,
    getAptitudeTestScore,


    getPersonalityIndex_User,
    getWorkValueDimensionScore_User,
    getInterestDimensionScore_User,
    getCareerFilamentScore_User,
    getAptitudeTestScore_User,
    generateCareerReport,
    getTalentPlusEmpSummary,
    getTalentPlusEmpStatus,
    getTalentPlusEmpAdminStatus
};

function assessmentSurveyLogout(props) {
    localStorage.removeItem('surveyuser')
    props.history.push(`/assessment/surveylogin`);

}
function assessmentLogout(props) {

    localStorage.removeItem('adminuser')
    // props.history.push(`/assessment/login`);
    props.history.push(`/assessment/sign-in`);

}


function userAssessmentLogout(props) {
    props.history.push(`/assessment/sign-in`);
}

function getTestSurveyQuestions(data) {
    return dispatch => {
        dispatch(request());
        assessmentService.getTestSurveyQuestions(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_TEST_SURVEY_QUESTION_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_TEST_SURVEY_QUESTION_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_TEST_SURVEY_QUESTION_FAILURE, error } }
}



function getAptitudeTestScore_User(data) {
    let obj = {
        getAptitudeTestScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getAptitudeTestScore_User(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_APTITUDE_TEST_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_APTITUDE_TEST_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_APTITUDE_TEST_FAILURE, error } }
}

function getAptitudeTestScore(data) {
    let obj = {
        getAptitudeTestScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getAptitudeTestScore(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_APTITUDE_TEST_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_APTITUDE_TEST_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_APTITUDE_TEST_FAILURE, error } }
}



function getPersonalityIndex(data) {
    let obj = {
        getPersonalityIndexData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getPersonalityIndex(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_PERSONALITY_INDEX_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_PERSONALITY_INDEX_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_PERSONALITY_INDEX_FAILURE, error } }
}

function getInterestDimensionScore(data) {
    let obj = {
        getInterestDimensionScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getInterestDimensionScore(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_INTEREST_DSCORE_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_INTEREST_DSCORE_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_INTEREST_DSCORE_FAILURE, error } }
}

function getWorkValueDimensionScore(data) {
    let obj = {
        getWorkValueDimensionScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getWorkValueDimensionScore(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_WORKER_VALUE_DSCORE_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_WORKER_VALUE_DSCORE_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_WORKER_VALUE_DSCORE_FAILURE, error } }
}

function getCareerFilamentScore(data) {
    let obj = {
        getCareerFilamentScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getCareerFilamentScore(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_FILAMENT_SCORE_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_FILAMENT_SCORE_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_FILAMENT_SCORE_FAILURE, error } }
}


function getTalentPlusEmpStatus(data) {
    let obj = {
        getTalentPlusEmpStatusData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getTalentPlusEmpStatus(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                    let status = assessment && assessment.getTalentPlusEmpStatusData && assessment.getTalentPlusEmpStatusData.status ? assessment.getTalentPlusEmpStatusData.status : 0;
                    if (status === 1) {
                        dispatch(this.getTalentPlusEmpSummary())
                    }
                },
                error => {
                    // dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_TALENT_PLUS_EMP_STATUS_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_TALENT_PLUS_EMP_STATUS_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_TALENT_PLUS_EMP_STATUS_FAILURE, error } }
}

function generateCareerReport(data) {
    let obj = {
        generateCareerReportData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.generateCareerReport(data)
            .then(
                assessment => {
                    let code = assessment && assessment.generateCareerReportData && assessment.generateCareerReportData.code ? assessment.generateCareerReportData.code : 0;
                    if (code === 1 || code === 2) {
                        dispatch(this.getTalentPlusEmpSummary())
                        dispatch(this.getTalentPlusEmpStatus())
                    }
                    dispatch(success(assessment));
                },
                error => {
                    // dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GENERATE_CAREER_REPORT_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GENERATE_CAREER_REPORT_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GENERATE_CAREER_REPORT_FAILURE, error } }
}

function getTalentPlusEmpSummary(data) {
    let obj = {
        getCareerFilamentScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getTalentPlusEmpSummary(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_TALENT_EMP_SUMMARYU_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_TALENT_EMP_SUMMARYU_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_TALENT_EMP_SUMMARYU_FAILURE, error } }
}

function getPersonalityIndex_User(data) {
    let obj = {
        getPersonalityIndexData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getPersonalityIndex_User(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_PERSONALITY_INDEX_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_PERSONALITY_INDEX_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_PERSONALITY_INDEX_FAILURE, error } }
}

function getInterestDimensionScore_User(data) {
    let obj = {
        getInterestDimensionScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getInterestDimensionScore_User(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_INTEREST_DSCORE_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_INTEREST_DSCORE_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_INTEREST_DSCORE_FAILURE, error } }
}

function getWorkValueDimensionScore_User(data) {
    let obj = {
        getWorkValueDimensionScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getWorkValueDimensionScore_User(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_WORKER_VALUE_DSCORE_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_WORKER_VALUE_DSCORE_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_WORKER_VALUE_DSCORE_FAILURE, error } }
}

function getCareerFilamentScore_User(data) {
    let obj = {
        getCareerFilamentScoreData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getCareerFilamentScore_User(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_FILAMENT_SCORE_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_FILAMENT_SCORE_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_FILAMENT_SCORE_FAILURE, error } }
}


function getTalentPlusEmpAdminStatus(data) {
    let obj = {
        getTalentPlusEmpAdminStatusData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getTalentPlusEmpAdminStatus(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_TALENT_PLUS_EMP_ADMIN_STATUS_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_TALENT_PLUS_EMP_ADMIN_STATUS_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_TALENT_PLUS_EMP_ADMIN_STATUS_FAILURE, error } }
}



function getCareerSurveyList(data) {
    let obj = {
        getCareerSurveyListData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getCareerSurveyList(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_CAREER_SURVEY_LIST_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_CAREER_SURVEY_LIST_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_CAREER_SURVEY_LIST_FAILURE, error } }
}

function getTestModule(data) {
    let obj = {
        getTestModuleData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getTestModule(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_TEST_MODULE_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_TEST_MODULE_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_TEST_MODULE_FAILURE, error } }
}

function getTalentBlogList(data) {
    return dispatch => {
        dispatch(request());
        assessmentService.getTalentBlogList(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_TALENT_BLOG_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_TALENT_BLOG_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_TALENT_BLOG_FAILURE, error } }
}

function getTalentResources(data) {
    return dispatch => {
        dispatch(request());
        assessmentService.getTalentResources(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_TALENT_RESOURCES_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_TALENT_RESOURCES_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_TALENT_RESOURCES_FAILURE, error } }
}

function submitExpertRating(data) {
    return dispatch => {
        dispatch(request());
        assessmentService.submitExpertRating(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                    dispatch(this.getExpertOrderBookList())
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.SUBMIT_RATING_EXPERT_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.SUBMIT_RATING_EXPERT_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.SUBMIT_RATING_EXPERT_FAILURE, error } }
}

function expertBookingSuccess(data) {
    return dispatch => {
        dispatch(request());
        assessmentService.expertBookingSuccess(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                    // dispatch(alertActions.success("Expert Successfully Booked!"));

                    if (assessment && assessment.expertBookingSuccessData && assessment.expertBookingSuccessData.id) {
                        let tempData = {
                            "id": assessment.expertBookingSuccessData.id
                        }
                        dispatch(this.getExpertOrderBookId(tempData))
                    } else {
                        dispatch(alertActions.error('Failed!'));
                        dispatch(failure('Failed!'))
                    }
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.EXPERT_BOOKING_SUCCESS_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.EXPERT_BOOKING_SUCCESS_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.EXPERT_BOOKING_SUCCESS_FAILURE, error } }
}

function getExpertOrderBookId(data) {
    return dispatch => {
        dispatch(request());
        assessmentService.getExpertOrderBookId(data)
            .then(
                assessment => dispatch(success(assessment)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.EXPERT_ORDER_BOOKID_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.EXPERT_ORDER_BOOKID_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.EXPERT_ORDER_BOOKID_FAILURE, error } }
}

function getExpertOrderBookList(data) {
    return dispatch => {
        dispatch(request());
        assessmentService.getExpertOrderBookList(data)
            .then(
                assessment => dispatch(success(assessment)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.EXPERT_ORDER_BOOK_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.EXPERT_ORDER_BOOK_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.EXPERT_ORDER_BOOK_FAILURE, error } }
}

function getExpertDetailsList(data) {
    return dispatch => {
        dispatch(request());
        assessmentService.getExpertDetailsList(data)
            .then(
                assessment => dispatch(success(assessment)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_EXPERT_DETAILS_LIST_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_EXPERT_DETAILS_LIST_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_EXPERT_DETAILS_LIST_FAILURE, error } }
}

function userAssessmentExpertOrderEmpty() {
    return dispatch => {
        dispatch(success({
            "userAssessmentExpertOrderPlaceData": {}
        }))
    };
    function success(assessment) { return { type: assessmentConstants.USER_EXPERT_ORDER_PLACE_SUCCESS, assessment } }
}

function userAssessmentExpertOrderPlace(data) {
    return dispatch => {
        dispatch(request());
        assessmentService.userAssessmentExpertOrderPlace(data)
            .then(
                assessment => dispatch(success(assessment)),
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.USER_EXPERT_ORDER_PLACE_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.USER_EXPERT_ORDER_PLACE_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.USER_EXPERT_ORDER_PLACE_FAILURE, error } }
}


function AssessmentUserLogout(props) {
    let obj = {
        AssessmentUserLogoutData: {}
    }
    return dispatch => {
        dispatch(success2(obj));
        dispatch(request(obj));

        assessmentService.userAssesmentlogout()
            .then(
                assessment => {
                    dispatch(success(assessment));
                    // dispatch(alertActions.success("Logged Out!"));
                    localStorage.removeItem('adminuser')
                    props.history.push(`/assessment/sign-in`);

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                    localStorage.removeItem('adminuser')
                    props.history.push(`/assessment/sign-in`);
                }
            );
    };
    function request() { return { type: assessmentConstants.USER_ASSESSMENT_LOGOUT_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.USER_ASSESSMENT_LOGOUT_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.USER_ASSESSMENT_LOGOUT_FAILURE, error } }

    function success2(assessment) { return { type: assessmentConstants.USER_ASSESSMENT_USER_PROFILE_SUCCESS, assessment } }

}

function userAssessmentUserProfile(data) {
    let obj = {
        userAssessmentUserProfileData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.userAssessmentUserProfile({})
            .then(
                assessment => {
                    dispatch(success(assessment));
                    if (data === "getAssessmentCartCount") {
                        dispatch(assessmentActions.getAssessmentCartCount());
                    }
                    console.log('The array exists!');
                },
                error => {
                    // dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.USER_ASSESSMENT_USER_PROFILE_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.USER_ASSESSMENT_USER_PROFILE_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.USER_ASSESSMENT_USER_PROFILE_FAILURE, error } }
}

function userAssessmentOrders(data) {
    let obj = {
        userAssessmentOrdersData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.userAssessmentOrders(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDERS_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDERS_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDERS_FAILURE, error } }
}

function userAssessmentSurveyLogin(data, props) {
    return dispatch => {
        dispatch(request());
        assessmentService.userAssessmentSurveyLogin(data)
            .then(
                assessment => {

                    console.log('assessment-11111--->', assessment)

                    if (assessment && assessment.userAssessmentSurveyLoginData
                        && assessment.userAssessmentSurveyLoginData.productCode
                        && assessment.userAssessmentSurveyLoginData.productCode === "TeenCareerGuidance"
                    ) {
                        props.history.push(`/assessment/career`);
                    } else {
                        props.history.push(`/assessment/survey`);
                    }
                    dispatch(success(assessment));

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_FAILURE, error } }
}

function userAssessmentSurveyLoginByOTP(data, props) {
    return dispatch => {
        dispatch(request());
        assessmentService.userAssessmentSurveyLoginByOTP(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                    props.history.push(`/assessment/survey`);

                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.USER_ASSESSMENT_SURVEY_LOGIN_BY_OTP_FAILURE, error } }
}

function getAssessmentPackagesList(data) {
    let obj = {
        getAssessmentPackagesListData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getAssessmentPackagesList(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_ASSESSMENT_PACKAGE_LIST_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_ASSESSMENT_PACKAGE_LIST_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_ASSESSMENT_PACKAGE_LIST_FAILURE, error } }
}

function getAssessmentPackagesById(data) {
    let obj = {
        getAssessmentPackagesByIdData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getAssessmentPackagesById(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_ASSESSMENT_PACKAGE_BY_ID_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_ASSESSMENT_PACKAGE_BY_ID_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_ASSESSMENT_PACKAGE_BY_ID_FAILURE, error } }
}


function assessmentAddToCart(data, props, route) {
    let obj = {
        assessmentAddToCartData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.assessmentAddToCart(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                    // dispatch(alertActions.success("Successfully Added To Cart!"));
                    if (route === 2) {
                        dispatch(this.getAssessmentCartItems())
                        dispatch(this.getAssessmentCartPriceTotal())

                    } else {
                        props.history.push(`/assessment/my-cart`);
                    }
                },
                error => {

                    console.log("error---->", error)
                    if (error === "Send valid token!!!") {
                        props.history.push(`/assessment/sign-in`);
                    }
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.ASSESSMENT_ADD_TO_CART_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.ASSESSMENT_ADD_TO_CART_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.ASSESSMENT_ADD_TO_CART_FAILURE, error } }
}
function getAssessmentCartItems(data) {
    let obj = {
        getAssessmentCartItemsData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getAssessmentCartItems(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_ASSESSMENT_CART_ITEMS_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_ASSESSMENT_CART_ITEMS_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_ASSESSMENT_CART_ITEMS_FAILURE, error } }
}


function updateCartItemQuantity(data) {
    let obj = {
        updateCartItemQuantityData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.updateCartItemQuantity(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                    dispatch(this.getAssessmentCartItems())
                    dispatch(this.getAssessmentCartPriceTotal())


                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.UPDATE_ASSESSMENT_CART_ITEMS_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.UPDATE_ASSESSMENT_CART_ITEMS_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.UPDATE_ASSESSMENT_CART_ITEMS_FAILURE, error } }
}


function getAssessmentCartPriceTotal(data) {
    let obj = {
        getAssessmentCartPriceTotalData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getAssessmentCartPriceTotal(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_ASSESSMENT_CART_PRICE_TOTAL_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_ASSESSMENT_CART_PRICE_TOTAL_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_ASSESSMENT_CART_PRICE_TOTAL_FAILURE, error } }
}


function deleteCartItem(data) {
    let obj = {
        deleteCartItemData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.deleteCartItem(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                    dispatch(this.getAssessmentCartItems())
                    dispatch(this.getAssessmentCartPriceTotal())
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.DELETE_ASSESSMENT_CART_ITEM_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.DELETE_ASSESSMENT_CART_ITEM_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.DELETE_ASSESSMENT_CART_ITEM_FAILURE, error } }
}




// function userAssessmentPackageBuy(data, props) {
//     return dispatch => {
//         dispatch(request());
//         assessmentService.userAssessmentPackageBuy(data)
//             .then(
//                 assessment =>{
//                     dispatch(success(assessment));
//                     if(assessment && assessment.userAssessmentPackageBuyData && assessment.userAssessmentPackageBuyData.id){
//                         let tempData = {
//                             "id":assessment.userAssessmentPackageBuyData.id
//                         }
//                         dispatch(this.getOrderBookList(tempData))
//                     }
//                 },
//                 error => {
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: assessmentConstants.USER_ASSESSMENT_PACKAGE_BUY_REQUEST } }
//     function success(assessment) { return { type: assessmentConstants.USER_ASSESSMENT_PACKAGE_BUY_SUCCESS, assessment } }
//     function failure(error) { return { type: assessmentConstants.USER_ASSESSMENT_PACKAGE_BUY_FAILURE, error } }
// }



function userAssessmentPackageOrderPlace(data, props) {
    let obj = {
        userAssessmentPackageOrderPlaceData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.userAssessmentPackageOrderPlace(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                    if (assessment && assessment.userAssessmentPackageOrderPlaceData && assessment.userAssessmentPackageOrderPlaceData.preOrderId) {
                        props.history.push(`/assessment/order-review/` + assessment.userAssessmentPackageOrderPlaceData.preOrderId);
                    }
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDER_PLACE_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDER_PLACE_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.USER_ASSESSMENT_PACKAGE_ORDER_PLACE_FAILURE, error } }
}


function userPlacePaymentOrder(data, props) {
    let obj = {
        userPlacePaymentOrderData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.userPlacePaymentOrder(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                    // if(assessment && assessment.userPlacePaymentOrderData && assessment.userPlacePaymentOrderData.id){
                    //     props.history.push(`/assessment/order-complete/`+assessment.userPlacePaymentOrderData.id);
                    // }
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.USER_PLACE_PAYMENT_ORDER_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.USER_PLACE_PAYMENT_ORDER_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.USER_PLACE_PAYMENT_ORDER_FAILURE, error } }
}


function getOrderBookList(data, isClear) {
    let obj = {
        getOrderBookListData: []
    }
    return dispatch => {
        dispatch(success(obj));
        if(!isClear){
            dispatch(request());
            assessmentService.getOrderBookList(data)
                .then(
                    assessment => {
                        dispatch(success(assessment));
                    },
                    error => {
                        dispatch(alertActions.error(error));
                        dispatch(failure(error))
                    }
                );
        }
    
    };
    function request() { return { type: assessmentConstants.GET_ORDER_BOOK_LIST_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_ORDER_BOOK_LIST_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_ORDER_BOOK_LIST_FAILURE, error } }
}



function userAssessmentResponseAnalytics(data) {
    let obj = {
        userAssessmentResponseAnalyticsData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.userAssessmentResponseAnalytics(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.USER_ASSESSMENT_RESPONSE_ANALYTICS_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.USER_ASSESSMENT_RESPONSE_ANALYTICS_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.USER_ASSESSMENT_RESPONSE_ANALYTICS_FAILURE, error } }
}



function userAssessmentOrderNameUpdate(data, temp) {
    let obj = {
        userAssessmentOrderNameUpdateData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.userAssessmentOrderNameUpdate(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                    dispatch(this.userAssessmentOrders(temp))
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.USER_ASSESSMENT_ORDER_NAME_UPDATE_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.USER_ASSESSMENT_ORDER_NAME_UPDATE_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.USER_ASSESSMENT_ORDER_NAME_UPDATE_FAILURE, error } }
}



function getAssessmentCartCount(data, temp) {
    let obj = {
        getAssessmentCartCountData: 0
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getAssessmentCartCount(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    // dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_ASSESSMENT_CART_COUNT_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_ASSESSMENT_CART_COUNT_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_ASSESSMENT_CART_COUNT_FAILURE, error } }
}



function saveAssessmentContactUs(data, temp) {
    let obj = {
        saveAssessmentContactUsData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.saveAssessmentContactUs(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.SAVE_ASSESSMENT_CONTACT_US_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.SAVE_ASSESSMENT_CONTACT_US_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.SAVE_ASSESSMENT_CONTACT_US_FAILURE, error } }
}


function updateAssessmentUserProfile(data, temp) {
    let obj = {
        updateAssessmentUserProfileData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.updateAssessmentUserProfile(data)
            .then(
                assessment => {
                    // dispatch(alertActions.success("Profile Successfully Updated!"));
                    dispatch(success(assessment));
                    dispatch(this.userAssessmentUserProfile())
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.UPDATE_ASSESSMENT_USER_PROFILE_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.UPDATE_ASSESSMENT_USER_PROFILE_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.UPDATE_ASSESSMENT_USER_PROFILE_FAILURE, error } }
}


function assessmentDownloadStatus(data, temp) {
    let obj = {
        assessmentDownloadStatusData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.assessmentDownloadStatus(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                    dispatch(this.userAssessmentUserProfile())
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.ASSESSMENT_DOWNLOAD_STATUS_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.ASSESSMENT_DOWNLOAD_STATUS_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.ASSESSMENT_DOWNLOAD_STATUS_FAILURE, error } }
}


function getAssessmentPackagesShortList(data) {
    let obj = {
        getAssessmentPackagesShortListData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getAssessmentPackagesShortList(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.ASSESSMENT_DOWNLOAD_SHORT_PRODUCT_LIST_FAILURE, error } }
}



function getAssessmentCartByPreOrderId(data) {
    let obj = {
        getAssessmentCartByPreOrderIdData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getAssessmentCartByPreOrderId(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.ASSESSMENT_CART_BY_PRE_ORDER_ID_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.ASSESSMENT_CART_BY_PRE_ORDER_ID_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.ASSESSMENT_CART_BY_PRE_ORDER_ID_FAILURE, error } }
}
function getAssessmentCartByPreOrderPriceTotal(data) {
    let obj = {
        getAssessmentCartByPreOrderPriceTotalData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.getAssessmentCartByPreOrderPriceTotal(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                },
                error => {
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.GET_ASSESSMENT_CART_PRE_ORDER_PRICE_TOTAL_FAILURE, error } }
}


function createSuccessOrderBook(data) {
    let obj = {
        createSuccessOrderBookData: {}
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.createSuccessOrderBook(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                    // dispatch(alertActions.success("Order Successfully Placed!"));

                    if (assessment && assessment.createSuccessOrderBookData && assessment.createSuccessOrderBookData.id) {
                        let tempData = {
                            "id": assessment.createSuccessOrderBookData.id
                        }
                        // dispatch(this.getOrderBookList(tempData))
                        // dispatch(this.saveSuccessOrderBook({}))

                    } else {
                        dispatch(alertActions.error('Failed!'));
                        dispatch(failure('Failed!'))
                    }
                },
                error => {
                    toast("Failed to Load!")
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.CREATE_SUCCESS_ORDER_BOOK_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.CREATE_SUCCESS_ORDER_BOOK_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.CREATE_SUCCESS_ORDER_BOOK_FAILURE, error } }
}


function saveSuccessOrderBook(data) {
    let obj = {
        saveSuccessOrderBookData: []
    }
    return dispatch => {
        dispatch(success(obj));
        dispatch(request());
        assessmentService.saveSuccessOrderBook(data)
            .then(
                assessment => {
                    dispatch(success(assessment));
                    if (assessment && assessment.saveSuccessOrderBookData && assessment.saveSuccessOrderBookData.length>0) {
                        console.log("saveSuccessOrderBookData--1------>",assessment.saveSuccessOrderBookData)
                        let tempData = {
                            "id": assessment.saveSuccessOrderBookData[assessment.saveSuccessOrderBookData.length-1]["id"]
                        }

                        dispatch(this.getOrderBookList(tempData))
                    } else {
                        console.log("saveSuccessOrderBookData--2------>",assessment)
                        // dispatch(alertActions.error('Failed!'));
                        // dispatch(failure('Failed!'))
                    }
                },
                error => {
                    toast("Failed to Load!")
                    dispatch(alertActions.error(error));
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: assessmentConstants.SAVE_SUCCESS_ORDER_BOOK_REQUEST } }
    function success(assessment) { return { type: assessmentConstants.SAVE_SUCCESS_ORDER_BOOK_SUCCESS, assessment } }
    function failure(error) { return { type: assessmentConstants.SAVE_SUCCESS_ORDER_BOOK_FAILURE, error } }
}
